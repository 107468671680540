import React from "react"
import { useIntl } from "gatsby-plugin-intl"    
import { ReactSlick as CarouselSlider } from './../../../../hooks/drupal/parseContentProcessor/Carousel/useReactSlick'

import Link from './../../Link' // extends Link from gatsby-plugin-intl
import ImageS4n from './../../image';
import ImageUtils from './../../../../utils/gatsby/image'

const logoPath = `custom/logo.png`;
const translation = {
    soft4net_hero_text: {
        pl: [
            { h1: `P&amp;P Biuro detektywistyczne`, strong: `kompleksowy montaż, serwis oraz obsługa`, bg: `custom/proklima/slider-1.jpeg`, },
            { h1: `P&amp;P Biuro detektywistyczne`, strong: `certyfikowana wiedza z zakresu klimatyzacji`, bg: `custom/proklima/slider-2.jpeg`, },
            { h1: `P&amp;P Biuro detektywistyczne`, strong: `doradztwo i dobór urządzeń`, bg: `custom/proklima/slider-3.jpeg`, },
        ],
        // en: [
        //     { h1: `P&amp;P Biuro detektywistyczne`, strong: `business matters, partner infidelity, betrayal, divorce, observation`, bg: `custom/header/1.jpg`, },
        //     { h1: `P&amp;P Biuro detektywistyczne`, strong: `debt collection, finding of people and property, criminal cases`, bg: `custom/header/2.png`, },
        //     { h1: `P&amp;P Biuro detektywistyczne`, strong: `environmental interview, employee monitoring, unfair competition`, bg: `custom/header/3.jpg`, },
        //     { h1: `P&amp;P Biuro detektywistyczne`, strong: `eavesdropping, security auditing, and conference room monitoring`, bg: `custom/header/4.jpg`, },
        // ],
    },
}

const ComponentStateless =  (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    return (
        <React.Fragment>
            <div className="is-cropped enable-carousel hero arrow-inside dots-inside">
                <CarouselSlider 
                    slidesToShow={1} slidesToScroll={1} dots={true} infinite={true} arrows={false} fade={true}
                    autoplay={true} speed={1000} autoplaySpeed={5000} pauseOnHover={true}
                >
                    {translation.soft4net_hero_text[intl.locale].map(({ h1, strong, bg }, index) => {
                        const img = ImageUtils.getGatsbyImageDataByRelativePath(bg);

                        // console.log(img);
                        const styleCover = {
                            // 'background-position': `50% 50%`,
                            // 'background-repeat': `no-repeat`,
                            'background-image': `url('${img.src}')`,
                        }

                        return (
                            <React.Fragment>
                                <div key={`slide-${index}`} className="banner-area banner-1" style={styleCover}>
                                    <div className="overlay gr-overlay"></div>
                                    <div className="d-table">
                                        <div className="d-table-cell">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-8 m-auto text-center col-sm-12 col-md-12">
                                                        <div className="banner-content content-padding">
                                                            <ImageS4n src={logoPath} alt={strong} className="pb-5" _maxWidth="300px" _maxHeight="5rem" />
                                                            {/* <h5 className="subtitle" dangerouslySetInnerHTML={{__html: h1}}></h5> */}
                                                            <h1 className="banner-title">{strong}</h1>
                                                            {/* <p className="d-none">{strong}</p> */}
                                                            {/* <Link to={`/`} className="btn btn-white btn-circled">{ t({ id: `soft4net_more` }) }</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </CarouselSlider>
            </div>
        </React.Fragment>
    )
}

export default ComponentStateless