/**
 * @see: https://github.com/akiran/react-slick
 * @see: https://react-slick.neostack.com/docs/get-started/
 * @see: https://react-slick.neostack.com/docs/example/custom-arrows
 */

import React from "react";

// @see: src/components/languageSwitcher.js
import { useIntl } from "gatsby-plugin-intl"

import { domToReact } from 'html-react-parser';
import Slider from "react-slick";
import _ from 'lodash'
import Helmet from "react-helmet"
import Img from "gatsby-image"
import styleToObject from 'style-to-object';
import classNames from 'classnames';
import ImageUtils from './../../../../../utils/gatsby/image'

const Arrow = (props) => {
    // const intl = useIntl();
    const { intl } = props;
    const t = intl.formatMessage;

    const { className, style, onClick } = props;
    const direction = props.prev ? `prev` : `next`;
// console.log(props)

    return (
        <div
            className={className}
            // style={{...style, display: "block", width: "auto", height: "auto"}}
            onClick={onClick}
        >
            <button role="button" title={ t({ id: `soft4net_pagination_${direction}` }) }>
                {props.prev && <i className="fas fa-chevron-left"></i>}
                {props.next && <i className="fas fa-chevron-right"></i>}
            </button>
        </div>
    );
}

// Extracts settings from class and from props
const extractSettings = (htmlNode, props, intl) => {
    // const intl = useIntl();
    const t = intl.formatMessage;

    // const styles = styleToObject(htmlNode.attribs.style);
    const classes = htmlNode.attribs.class.split(` `);

    const classColumns = classes.filter(className => className.startsWith(`columns-`) === true); // columns-1, columns-3
    const slidesToShow = Number(classColumns[0].split(`-`)[1]);

    const centerMode = classes.includes(`centerMode-true`) // class="centerMode-true"
    const centerSettings = {
        centerMode: centerMode,
        // centerPadding: `100px`,
    }

    const className = classNames({
        [`center`]: centerMode,
    });

    return {
        className: className,
        slidesToShow: slidesToShow,
        ...centerSettings,
    };
}
  
const extractImages = (htmlNode, intl) => {
    // const intl = useIntl();
    const t = intl.formatMessage;

    let figure = htmlNode;
    let figureUl = null;
    let figureFigcaption = null;

// console.log(figure)

    if (typeof figure.children[0] !== `undefined` && `tag` === figure.children[0].type && `ul` === figure.children[0].name) {
        figureUl = figure.children[0];
    }

    if (typeof figure.children[1] !== `undefined` && `tag` === figure.children[1].type && `figcaption` === figure.children[1].name) {
        figureFigcaption = figure.children[1];
    }

    return figureUl && figureUl.children.map((li, index) => {
        const figure = li.children[0];
        const htmlNodeImage = figure.children[0];
        const figcaption = figure.children[1];

        let figcaptionText = null;
        if (figcaption) {
            // console.log(figcaption)
            figcaptionText = domToReact(figcaption.children)
        }

        let useGatsbyImage = true;
        let uuid = htmlNodeImage.attribs[`data-entity-uuid`],
        img = ImageUtils.getGatsbyImageDataByUuid(uuid);

// console.log(htmlNodeImage)
    
        if (img) {
            if (_.isEmpty(htmlNodeImage.attribs[`alt`])) {
                let oDrupalMediaImageData = ImageUtils.getDrupalMediaImageDataByUuid(uuid, intl.locale);
                htmlNodeImage.attribs[`alt`] = oDrupalMediaImageData ? oDrupalMediaImageData.alt : ``;

                // if (img.alt) {
                //     htmlNodeImage.attribs['alt'] = img.alt;
                // }
            }

            if (useGatsbyImage) {
                return (
                    <React.Fragment>
                        <Img 
                            fluid={img}
                            {...htmlNodeImage.attribs}
                            // loading="eager"
                            // objectFit="cover"
                        />
                        {figcaptionText && <div className="description">{figcaptionText}</div>}
                    </React.Fragment>
                )
            } else {
                // @see: https://owlcarousel2.github.io/OwlCarousel2/demos/lazyLoad.html
                htmlNodeImage.attribs[`src`] = img.src;
                // htmlNodeImage.attribs[`data-src`] = img.src;
                // htmlNodeImage.attribs['class'] = classNames({
                //     [`${htmlNodeImage.attribs['class']}`]: true,
                //     [`owl-lazy`]: true,
                // });

                return (
                    <React.Fragment>
                        <img {...htmlNodeImage.attribs} />
                        {figcaptionText && <div className="description">{figcaptionText}</div>}
                    </React.Fragment>
                )
            }
        }
    });
}
  
export const ReactSlick = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { htmlNode } = props; // htmlNode is available when we process Drupal HTML content
    const attribs = props.children ? props.attribs : {...htmlNode.attribs};

    const settingsExtracted = props.children ? {...props} : extractSettings(htmlNode, props, intl);
    delete settingsExtracted.children;

    const slides = props.children ? props.children : extractImages(htmlNode, intl);

    // console.log(slides)
    // console.log(settingsExtracted)

    // @see: https://react-slick.neostack.com/docs/api
    const settings = {
        dots: true,
        infinite: true,
        // slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <Arrow intl={intl} next />,
        prevArrow: <Arrow intl={intl} prev />,
        accessibility: true,
        lazyLoad: false, // no need to we use Gatsby image 
        // fade: true, // if enabled slidesToShow more than 1 is not working!
        adaptiveHeight: false,

        ...settingsExtracted,

        // autoplay: true,
        // speed: 2000,
        // autoplaySpeed: 2000,
        // pauseOnHover: true,
        
        // responsive: [
        //     {
        //         breakpoint: 1200, // Extra large devices (large desktops, 1200px and up)
        //         settings: {
        //             slidesToShow: 4,
        //             slidesToScroll: 4,
        //             infinite: true,
        //             dots: true,
        //         }
        //     },
        //     {
        //         breakpoint: 992, // Large devices (desktops, 992px and up)
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 3,
        //             infinite: true,
        //             dots: true,
        //         }
        //     },
        //     {
        //         breakpoint: 768, // Medium devices (tablets, 768px and up)
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 2,
        //             initialSlide: 2,
        //         }
        //     },
        //     {
        //         breakpoint: 576, // Small devices (landscape phones, 576px and up)
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //         }
        //     }
        // ],
    };

    // console.log(settings)

    return (
        <React.Fragment>
            {false && <Helmet>
                {/* @see: https://react-slick.neostack.com/docs/get-started/ */}
                {/* <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />  */}
                {/* <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" /> */}

                {/* <!-- Add the slick-theme.css if you want default styling --> */}
                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/>
                {/* <!-- Add the slick-theme.css if you want default styling --> */}
                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"/>
            </Helmet>}

            <div {...attribs}>
                {/* <h2>Custom Arrows</h2> */}
                <Slider {...settings}>
                    {slides.map((slide, index) => {
                        return slide;
                    })}
                </Slider>
            </div>
        </React.Fragment>
    )
}

export default (htmlNode = null) => {
    // return htmlNode;
    return <ReactSlick />
}